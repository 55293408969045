import React, { useEffect, useState } from 'react';
import tw, {styled} from 'twin.macro';
import axios from 'axios';
import constants from '../constants';
import Loader from './loader';

const Wrapper = styled.section`
  padding: 5rem 0.5rem;
  background: #f3f5f7;
  @media (min-height: 759px) and (min-width: 700px){
    padding-top: 10rem;
    height: 100%;
  }
`;

const ContactWrapper = styled.div`
  ${tw`max-w-screen-xl mx-auto`}
`;

const SectionTitle = styled.h1`
  font-weight: 500;
  font-size: 26px;
`;

const TopicsWrapper = styled.div`
  ${tw`grid grid-cols-2 lg:grid-cols-6 md:grid-cols-3 gap-3`}
  // margin-top: 2rem;
  text-align: center;
  margin: 2rem auto;
`;

const TopicCard = styled.a`
  display: inline-block;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 3px;
  padding: 1rem 0.5rem;
  transition: 200ms;
  &:hover{ border-color: #132664; }
`;


const TopicImg = styled.img`
  margin: 0 auto;
  display: block;
  margin-bottom: 0.25rem;
  width: 55px;
  height: 55px;
  background: rgba(0, 198, 246, .18);
  border-radius: 50%;
  padding: 0.35rem;
`;

export default function Topics() {

  const [topics, setTopics] = useState({
    loading: true,
    data: []
  });
  const [error, setError] = useState(false); // when something went wrong, e.g: 500 ERROR

  useEffect(() => {
    (async function(){
      try{
        let resp = await axios.get(`${constants.API_URL}/topics`);
        console.log(resp.data)
        setTopics({
          loading: false,
          data: resp.data
        });
      }catch(err){
        setTopics({ loading: false, data: [] });
        setError(true);
      }
    }());
  }, []);

  return (
    <Wrapper id="topics">
      <ContactWrapper>
        <div>
          <SectionTitle> Topics that you may want to read about. </SectionTitle>
          <p tw="text-gray-400">Read what islam say about these topics.</p>
        </div>
        {
          topics.loading ? (
            <Loader width="35px" />
          ) : (
            error ? (
              <div tw="flex mt-10">
                <svg width="24" height="24" tw="text-blue-500" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 6C12.5523 6 13 6.44772 13 7V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V7C11 6.44772 11.4477 6 12 6Z" fill="currentColor" /><path d="M12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16Z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12Z" fill="currentColor" /></svg>
                <div tw="text-blue-400 ml-1">
                  <p> We couldn't grab the topics, looks like something went wrong. </p>
                  <p> please check later. </p>
                </div>
              </div>
            ) : (
              topics.data.length === 0 ? (
                <div tw="flex mt-10">
                  <span tw="text-blue-300">
                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M12 20h9"></path><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path></svg>
                  </span>
                  <div tw="text-blue-400 ml-2">
                    <p> No topics were found, we are still writing them... </p>
                    <p> please come back later to see all the topics. </p>
                  </div>
                </div>
              ) : (
                <TopicsWrapper>
                  {
                    topics.data.map(topic => (
                      <TopicCard href={`/topic?id=${topic.id}`} target="_blank" rel="noopener">
                        <TopicImg src={`${constants.API_URL}${topic.icon.url}`} />
                        <p tw="font-medium">{topic.name}</p>
                      </TopicCard>
                    ))
                  }
                </TopicsWrapper>
              )
            )
          )
        }
      </ContactWrapper>
    </Wrapper>
  )
}
