import React from 'react';
import tw, {styled} from 'twin.macro';
import featureImg1 from '../images/24-hours.svg';
import featureImg2 from '../images/care.svg';
import featureImg3 from '../images/privacy.svg';
import featureImg4 from '../images/chat.svg';
import featureImg5 from '../images/anyquestion.svg';

const Wrapper = styled.section`
  // margin-top: 5rem;
  padding: 5rem 1rem;
  background: #07288e;
  @media (min-height: 759px) and (min-width: 700px){
    padding-top: 6rem;
    height: 100%;
  }
  @media (min-height: 759px) and (min-width: 750px){
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ContentWrapper = styled.div`
  ${tw`max-w-6xl mx-auto`}
`;

const FeatureItem = styled.div`
  display: flex;
  align-items: center;
  & img {
    ${tw` w-14 `}
  }
  &:not(:first-child){
    margin-top: 2rem;
  }
`;

const FeatureTitle = styled.span`
  font-weight: 500;
  color: #f3f5f7;
  ${tw` md:text-xl `}
`;

export default function Features() {
  return (
    <Wrapper id="features">
      <ContentWrapper>
        <div tw="grid grid-cols-1 md:grid-cols-2 gap-x-32 gap-y-4">
          <div tw="mb-4 row-span-2">
            <span tw="font-medium text-2xl text-gray-200 block"> We are always there to help you and answer your questions. </span>
            <p tw="text-gray-400 mt-2">
              Our knowledgeful, friendly and caring muslims are always there 24/7 for you to help. if you have any questions, or want to learn more about the real islam, not what you see on media everyday.
            </p>
            {/* < */}
          </div>
          <div>
            <FeatureItem>
              <img src={featureImg1} alt="img" />
              <div tw="ml-2">
                <FeatureTitle> Available for you 24/7 </FeatureTitle>
                <p tw="text-gray-300 text-sm"> All day all week, we are there to give a hand. </p>
              </div>
            </FeatureItem>
            <FeatureItem>
              <img src={featureImg2} alt="img" />
              <div tw="ml-2">
                <FeatureTitle> Careful and friendly </FeatureTitle>
                <p tw="text-gray-300 text-sm"> We carfuly listen to your concerns and help you. </p>
              </div>
            </FeatureItem>
            <FeatureItem>
              <img src={featureImg5} alt="img" />
              <div tw="ml-2">
                <FeatureTitle> Talk in any topic you want. </FeatureTitle>
                <p tw="text-gray-300 text-sm"> Chat with us in any topic you want, ask us anything. </p>
              </div>
            </FeatureItem>
            <FeatureItem>
              <img src={featureImg3} alt="img" />
              <div tw="ml-2">
                <FeatureTitle> Full privacy and security </FeatureTitle>
                <p tw="text-gray-300 text-sm"> Your privacy and security is our high priority. </p>
              </div>
            </FeatureItem>
            <FeatureItem>
              <img src={featureImg4} alt="img" />
              <div tw="ml-2">
                <FeatureTitle> Chat in your favorite platform. </FeatureTitle>
                <p tw="text-gray-300 text-sm"> We are available on Whatsapp, facebook, Telegram... </p>
                <p tw="text-gray-300 text-sm"> choose your favorite plaform and lets get started. </p>
              </div>
            </FeatureItem>
          </div>
        </div>
      </ContentWrapper>
    </Wrapper>
  )
}
