import React, { useState } from 'react'
import tw, {styled} from 'twin.macro';
import LinksModal from './linksModal';

const Wrapper = styled.section`
  ${tw` px-5 py-20 `}
  background: #2196F3;
  background: linear-gradient(0deg, #2196F3 0%, #07288e 100%);
  @media (min-height: 759px) and (min-width: 700px){
    padding: 2rem 0.5rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ChatBtn = styled.span`
  ${tw`bg-blue-500 rounded-full text-white font-medium px-5 py-3 text-center max-w-xs block mx-auto`}
  background: linear-gradient(rgb(5, 77, 165), #00408d) rgb(5, 77, 165);
  transition: 250ms;
  cursor: pointer;
  box-shadow: 0 5px 15px rgb(4, 74, 161, 0.65);
  &:hover{
    box-shadow: 0 2px 7px rgb(4, 74, 161, 0.55);
  }
  &:active{
    box-shadow: 0 0 0px rgb(4, 74, 161, 0.65);
  }
`;

export default function Chat() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <Wrapper id="chat">
      <div>
        <div tw="mb-10">
          <h2 tw="text-center font-medium text-2xl md:text-3xl text-white"> Let's get started, send us a message. </h2>
          <p tw="text-center text-sm md:text-base text-gray-200 mt-2"> Choose your favorite platform, and let's have a great chat. </p>
        </div>

        <ChatBtn onClick={() => setIsModalOpen(true)}> Get Started </ChatBtn>
      </div>

      {
        isModalOpen ? (
          <LinksModal
            onClose={() => setIsModalOpen(false)}
          />
        ) : null
      }
    </Wrapper>
  )
}
