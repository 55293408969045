import React, { useState } from 'react';
import tw, {styled} from 'twin.macro';
import hudaWhiteLogo from '../images/huda_white.svg';
import hudaColoredLogo from '../images/huda_colored.svg';

const Wrapper = styled.header`
  padding: 0.65rem 0;
  // background: white;
  background: transparent;
  ${tw`px-3 lg:px-20`}
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const NavbarLink = styled.a`
  display: block;
  padding: 1rem 0.5rem;
  cursor: pointer;
  transition: 250ms;
  font-weight: 500;
  &:hover{ color: #333 }
  text-align: center;
`;

const ChatBtn = styled.a`
  display: block;
  padding: 0.75rem 0.5rem;
  cursor: pointer;
  transition: 250ms;
  font-weight: 500;
  text-align: center;
  color: white;
  background: #00c6f6;
  max-width: 200px;
  margin: 0 auto;
  margin-top: 1.5rem;
  border-radius: 50px;
  &:hover{ background: #00bae8 }
`;

const NavContainer = styled.div`
  background: #fff;
  padding: 1rem 0.5rem;
  padding-top: 4rem;
  position: absolute;
  top: 0; left: 0;
  width: 100%;
  border-radius: 0 0 15px 15px;
  z-index: -1;
  animation: bla 250ms;

  @keyframes bla {
    from { opacity: 0; }
    to { opacity: 1; }
  } 
`;

export default function MobileNavbar() {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleScroll = () => setIsNavOpen(false);

  return (
    <Wrapper>
      <Nav>
        <div tw="w-full">
          <img src={isNavOpen ? hudaColoredLogo : hudaWhiteLogo} tw="w-32" />
        </div>
        <span tw="text-white cursor-pointer" onClick={() => setIsNavOpen(!isNavOpen)} style={isNavOpen ? {color: '#000'} : null}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 6C2 5.44772 2.44772 5 3 5H21C21.5523 5 22 5.44772 22 6C22 6.55228 21.5523 7 21 7H3C2.44772 7 2 6.55228 2 6Z" fill="currentColor" /><path d="M2 12.0322C2 11.4799 2.44772 11.0322 3 11.0322H21C21.5523 11.0322 22 11.4799 22 12.0322C22 12.5845 21.5523 13.0322 21 13.0322H3C2.44772 13.0322 2 12.5845 2 12.0322Z" fill="currentColor" /><path d="M3 17.0645C2.44772 17.0645 2 17.5122 2 18.0645C2 18.6167 2.44772 19.0645 3 19.0645H21C21.5523 19.0645 22 18.6167 22 18.0645C22 17.5122 21.5523 17.0645 21 17.0645H3Z" fill="currentColor" /></svg>
        </span>
        {
          isNavOpen ? (
            <NavContainer>
              <NavbarLink href="#topics" onClick={(handleScroll)}> Topics </NavbarLink>
              <NavbarLink href="#features" onClick={handleScroll}> Features </NavbarLink>
              <NavbarLink href="#faq" onClick={handleScroll}> FAQ </NavbarLink>
              <NavbarLink href="#contact" onClick={handleScroll}> Contact </NavbarLink>
              <ChatBtn
                href="#chat"
                onClick={handleScroll}
              >
                Chat
              </ChatBtn>
            </NavContainer>
          ) : null
        }
      </Nav>
    </Wrapper>
  )
}
