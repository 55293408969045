import React from 'react';
import tw, {styled, css} from 'twin.macro';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import imageOne from '../images/banner-1.jpg';
import imageTwo from '../images/banner-3.jpg';
import imageThree from '../images/banner-2.jpg';

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

const SliderItem = styled.div`
  height: 650px;
  height: 100vh;
  width: 100%;
  background-image: url('${(props) => props.img}');
  background-repeat: no-repeat;
  background-position: ${(props) => props.position || '50% 50%'};
  background-size: cover;
  position: relative;
  z-index: 0;
  overflow: hidden;
  &::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; height: 100%;
    // background: rgba(0,0,0,0.75);
    // background: rgba(32, 46, 87, 0.78);
    background: linear-gradient(180deg, rgba(0, 198, 246, .25) 0%, rgb(7, 40, 142) 100%);
    z-index: 1;
  }
  z-index: 10;
`;

const SliderItemContent = styled.div`
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 3;
  color: #fff;
  width: 100%;
  padding: 0 0.5rem;
`;

const SliderContentText = styled.p`
  ${tw`text-gray-300 text-base md:text-lg`}
`;

const SliderItemTitle = styled.h2`
  font-weight: 900;
  // font-size: 35px;
  ${tw` text-3xl md:text-5xl `}
`;

const BannerBtn = styled.a`
  display: inline-block;
  background: #00c6f6;
  color: #061442;
  ${tw`px-4 py-2 rounded-full font-medium mt-4 inline-block shadow`}
  cursor: pointer;
  transition: 250ms;
  &:hover{ background: #03A9F4; }
`;

export default function Banner({ isOnMobile }) {
  const AutoplaySlider = withAutoplay(AwesomeSlider);

  const handleScroll = (n) => e => {
    if(typeof window !== 'undefined'){
      if(!isOnMobile){
        window.scrollTo(0, window.innerHeight * n)
      }else{
        return false;
      }
    }
  }
  return (
    <Wrapper id="banner">
      <AutoplaySlider
        play={true}
        cancelOnInteraction={false} // should stop playing on user interaction
        interval={3000}
        bullets={false}
        style={{height: '100vh'}}
        animation="foldOutAnimation"
      >
        <div className="w-full">
          <SliderItem img={imageThree} position="50% 70%">
            <SliderItemContent>
              <div>
                <SliderItemTitle> Learn about the real islam. </SliderItemTitle>
                <SliderContentText tw="mt-5">Talk with our knowledgeful, friendly muslims</SliderContentText>
                <SliderContentText>and get to know about the real islam.</SliderContentText>
                <BannerBtn href="#chat" onClick={handleScroll(3)}> Chat Now </BannerBtn>
              </div>
            </SliderItemContent>
          </SliderItem>
        </div>
        <div className="w-full">
          <SliderItem img={imageTwo}>
            <SliderItemContent>
              <div>
                <SliderItemTitle> We are here to help. </SliderItemTitle>
                <SliderContentText tw="mt-5"> Our friendly muslims are available 24/7 </SliderContentText>
                <SliderContentText>to help you and answer all of your questions.</SliderContentText>
                <BannerBtn href="#features" onClick={handleScroll(2)}> See Features </BannerBtn>
              </div>
            </SliderItemContent>
          </SliderItem>
        </div>
        <div className="w-full">
          <SliderItem img={imageOne}>
            <SliderItemContent>
              <div>
                <SliderItemTitle> Reach out. </SliderItemTitle>
                <SliderContentText tw="mt-5"> Got any query? </SliderContentText>
                <SliderContentText> or just want to say a good word? </SliderContentText>
                <BannerBtn href="#contact" onClick={handleScroll(5)}> Contact us </BannerBtn>
              </div>
            </SliderItemContent>
          </SliderItem>
        </div>
      </AutoplaySlider>
      {/* <ScrollDownIndicator>
        <svg tw="w-6 h-6" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
          <path d="M19 14l-7 7m0 0l-7-7m7"></path>
        </svg>
      </ScrollDownIndicator> */}
    </Wrapper>
  )
}
