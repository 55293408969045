import React from 'react'
import Fullpage, { FullPageSections, FullpageSection, FullpageNavigation } from '@ap.cx/react-fullpage'
import Banner from '../components/banner';
import Chat from '../components/Chat';
import Features from '../components/features';
import Navbar from '../components/navbar';
import MobileNavbar from '../components/mobileNavbar';
// import { Link } from 'gatsby'

import SEO from '../components/seo';
import Topics from '../components/topics';
import Faq from '../components/faq';
import Footer from '../components/footer';

// const Wrapper = styled.div`
//   ${tw` bg-gray-500 `}
// `;

const IndexPage = () => {
  let windowWidth = 0;
  let windowHeight = 0;
  if(typeof window !== "undefined"){
    windowWidth = window.innerWidth;
    windowHeight = window.innerHeight;
  }
  return (
    <div>
      <SEO title="Home" />
      {
        windowWidth >= 750 && windowHeight >= 755 ? (
          <>
            <Navbar />
            <Fullpage>
              <FullpageNavigation />
              <FullPageSections>
                <FullpageSection><Banner /></FullpageSection>
                <FullpageSection style={{height: '80vh'}}><Topics /></FullpageSection>
                <FullpageSection><Features /></FullpageSection>
                <FullpageSection style={{height: '75vh'}}><Chat /></FullpageSection>
                <FullpageSection><Faq /></FullpageSection>
                <FullpageSection><Footer /></FullpageSection>
              </FullPageSections>
            </Fullpage>
          </>
        ) : (
          <>
            <MobileNavbar />
            <Banner isOnMobile />
            <Topics />
            <Features />
            <Chat />
            <Faq />
            <Footer />
          </>
        )
      }
    </div>
  )
}

export default IndexPage
