const defaultQuestionsData = [
  {
    id: 1,
    question: 'What does Islam teach?',
    answer: `
    Islam’s primary message, as understood by the overwhelming majority of Muslims, is the continuation of the Abrahamic monotheistic tradition’s belief in one God. The three major dimensions of Islam include beliefs, ritual practices, and the effort to improve one’s character and actions. There are six major beliefs in Islam and five central practices that are referred to as the Five Pillars. The last dimension of Islam focuses on the cultivation of excellent moral character to better oneself and the world around oneself. It teaches a set of values that promote life, liberty, equality and justice. Some of these values include:
      - Respect for the earth and all creatures
      - Care and compassion for those less fortunate
      - The importance of seeking knowledge
      - Honesty and truthfulness in word and deed
      - Striving continuously to improve oneself and the world
    `
  },
  {
    id: 2,
    question: 'What are the major beliefs of Muslims?',
    answer: `
    The six major beliefs in Islam, as understood by the majority of Sunni Muslims, are:
      - belief in God.
      - belief in angels.
      - belief in God’s prophets/messengers.
      - belief in God’s revelations in the form of holy scriptures given to the messengers.
      - belief in an afterlife that follows the Day of Judgment on which people will be held accountable for their actions and compensated accordingly in the afterlife.
      - belief in God’s divine will and His knowledge of what happens in the world.
    `
  },
  {
    id: 3,
    question: 'What are the foundational sources of Islamic beliefs and practices?',
    answer: `
    The primary sources of knowledge about Islam are the Qur’an, which adherent Muslims believe is the divinely revealed word of God, and the Sunnah, which refers to the example or precedent of the Prophet Muhammad (i.e., what he said, did, approved, disapproved, caused, ordered, or allowed to happen). Much of what is known about the Sunnah is from the collection of sayings or reports known as Hadith, or prophetic tradition. The Hadith describe actions of the Prophet Muhammad or actions that his companions attributed to his teachings. Hadith also elaborate on and provide context to the Qur’an.

    Though both Sunnis and Shi’as revere and respect the descendants of the Prophet Muhammad, many Shi’a’s consider the rulings of the twelve Imams a primary source having a status similar to that of the aforementioned sources. Other sources may exist for different Muslim sects.

    In addition to these primary sources, Muslims have also traditionally relied on the following additional sources: scholarly consensus: that is, the agreement of knowledgeable scholars upon a particular issue; and analogical reasoning: that is, the application of principles or laws derived from the Qur’an and Sunnah to similar situations not explicitly addressed by them. The lived experience of Islam, which naturally varies widely not only in different cultures but also between different individuals, also impacts and determines a Muslim’s understanding and practice of Islam.
    `
  },
  {
    id: 4,
    question: 'Why do Muslims believe that the Prophet Muhammad is the final prophet?',
    answer: `
    Muslims believe that the Prophet Muhammad is the final prophet on the basis of statements in Islamic scripture, including the following Qur’anic verse: “Muhammad is not the father of any of your men, but (he is) the Messenger of God, and the Seal of the Prophets and God has full knowledge of all things.” (Qur’an, 33:40) There are also various Hadith (prophetic sayings) which designate Muhammad as “the Seal of the Prophets.” Muslims believe that the Prophet Muhammad was preceded by a long succession of prophets before him that include Adam, Noah, Abraham, David, and Jesus. Muslims believe that all the prophets were sent by God and that some of them foretold the coming of the Prophet Muhammad.
    `
  },
  {
    id: 5,
    question: 'Do Muslims believe in the Bible and Torah?',
    answer: `
    While the majority of Muslims believe in the four previous holy books or scriptures mentioned in the Qur’an as original revelations to the prophets (the Scrolls as revealed to Abraham; the Torah as revealed to Moses; the Psalms as revealed to David; the Gospel as revealed to Jesus), they do not believe that they have been preserved in the original form or language in which they were first revealed. However, Muslims believe that the Qur’an affirms many of the same teachings of these previous scriptures.
    `
  },
  {
    id: 6,
    question: 'What is your proof of the authenticity of the Qur’an?',
    answer: `
    Muslim historians and scholars describe the history of the Qur’an and the efforts of Muslims since the early days of Islam to preserve the Qur’an in its original form. During the Prophet Muhammad’s life, scores of people memorized, recited, and wrote down the Qur’an. Shortly after the death of the Prophet Muhammad, the Qur’an was compiled and transcribed by experts who carefully verified every verse by matching it against both the written word and memorized verses. The completed transcript was then copied and distributed across the growing Islamic empire. These copies served as the basis for all copies of the Qur’an written or printed since. Today these earliest written versions of the Qur’an are identical to contemporary copies of the Qur’an.

    While translations of the Qur’an may vary, all copies of the Qur’an in Arabic contain nearly identical language. This standardization, coupled with the millions of people who continue to memorize the entire Qur’an, ensures the text’s authenticity.
    `
  },
  {
    id: 7,
    question: 'Are Muslims allowed to marry people of other faiths?',
    answer: `
    Muslim men are allowed to marry women who are from the “People of the Book,” generally defined as Christians and Jews. If he knew she is a good and rightous women, who is protected from immorality, and she is the one who is not known to abuse immoral actions.

    The reverse, i.e., a Muslim woman marrying a man outside her religion, is not allowed on the grounds that her husband might not guarantee her the right to practice her religion, since he may not to have the same obligation to respect her religion that a Muslim has towards his Christian or Jewish wife. Therefore, for the protection of her freedom of religion, a Muslim woman has traditionally been required to marry a man who will give her the right to practice her faith—that is, a Muslim. \n\n\n\n
    "Today all good, pure foods have been made lawful for you. Similarly, the food of the People of the Book1 is permissible for you and yours is permissible for them. And ˹permissible for you in marriage˺ are chaste believing women as well as chaste women of those given the Scripture before you—as long as you pay them their dowries in wedlock, neither fornicating nor taking them as mistresses. And whoever rejects the faith, all their good deeds will be void ˹in this life˺ and in the Hereafter they will be among the losers." [Surat Al-ma’idah]
    `
  },
]

export default defaultQuestionsData;
