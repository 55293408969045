import React, { useState } from 'react';
import tw, {styled} from 'twin.macro';
import hudaWhiteLogo from '../images/huda_white.svg';

const Wrapper = styled.header`
  padding: 0.65rem 0;
  // background: white;
  background: transparent;
  ${tw`px-3 lg:px-20`}
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const NavbarLink = styled.span`
  display: inline-block;
  padding: 0.3rem 0.5rem;
  margin-right: 0.5rem;
  cursor: pointer;
  transition: 250ms;
  font-weight: 500;
  &:hover{ color: #fff }
`;

const NavbarLinks = styled.a`
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, .85);
`;

export default function Navbar() {
  return (
    <Wrapper>
      <Nav>
        <div tw="w-full">
          {/*  width="50%" height="50%" */}
          <img src={hudaWhiteLogo} tw="w-40" />
        </div>
        <NavbarLinks>
          <NavbarLink onClick={() => window.scrollTo(0, window.innerHeight * 1)}> Topics </NavbarLink>
          <NavbarLink onClick={() => window.scrollTo(0, window.innerHeight * 2)}> Features </NavbarLink>
          <NavbarLink onClick={() => window.scrollTo(0, window.innerHeight * 3)}> Chat </NavbarLink>
          <NavbarLink onClick={() => window.scrollTo(0, window.innerHeight * 4)}> FAQ </NavbarLink>
          <NavbarLink onClick={() => window.scrollTo(0, window.innerHeight * 5)}> Contact </NavbarLink>
          {/* <ChatBtn> Chat </ChatBtn> */}
        </NavbarLinks>
      </Nav>
    </Wrapper>
  )
}
