/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import tw, {styled} from 'twin.macro';
import axios from 'axios';
import Overlay from './overlay';
import constants from '../constants';
import MessengerIcon from '../images/messenger.svg';
import WhatsappIcon from '../images/whatsapp.svg';
import TelegramIcon from '../images/telegram.svg';
import Loader from './loader';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

const ModalContainer = styled.div`
  width: 540px; // small by default
  z-index: 15;
  margin: 0 .5rem;
  ${(props) => (
    props.size === 'md' ? `width: 720px`
      : props.size === 'lg' ? `width: 900px`
        : null
  )}
  border-radius: 20px;
  background: #fff;
  display: flex;
  max-height: calc(100vh - 32px);
  overflow: auto;
  overflow-x: hidden;
  animation: zoomIn 45ms;
  @keyframes zoomIn{
    from { transform: scale(1.13) }
    to { transform: scale(1) }
  }
  @media (min-height: 759px) and (min-width: 700px){
    margin-top: 40%;
  }
`;

const ModalContentWrapper = styled.div`
  width: 100%;
  flex: 1 1 auto;
  min-height: 0;
  min-width: 0;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const IconLink = styled.a`
  cursor: pointer;
  ${tw` block border-2 border-gray-200 rounded-full p-2 transition `}
  &:hover{
    border-color: #00c6f6;
  }
  &:not(:last-child){ margin-right: 2rem; }
`;

export default function LinksModal({ onClose, size }) {
  const [links, setLinks] = useState({
    loading: true,
    data: [
      // {name: 'whatsapp', url: '#'},
      {name: 'messenger', url: 'https://www.facebook.com/huda.chat/'},
      // {name: 'telegram', url: '#'}
    ]
  });

  useEffect(() => {
    (async function(){
      try{
        let resp = await axios.get(`${constants.API_URL}/chat-links`);
        setLinks({
          loading: false,
          data: resp.data
        });
      }catch(err){
        setLinks({ loading: false, data: links.data });
      }
    }());
  }, []);

  return (
    <Wrapper>
      <Overlay zIndex="10" onClick={onClose} />
      <ModalContainer size={size}>
        <ModalContentWrapper>
          <div tw="py-5">
            <span tw="block text-center font-medium text-xl mb-5"> Choose your favorite platform. </span>

            <div tw="flex items-center justify-center my-20">
              {
                links.loading ? (
                    <Loader width="60px" />
                ) : (
                  links.data.map(link => (
                    link.name === 'messenger' ? (
                      <IconLink href={link.url} target="_blank" rel="noopener">
                        <img src={MessengerIcon} tw="w-10 h-10" alt="Messenger" />
                      </IconLink>
                    ) : link.name === 'whatsapp' ? (
                      <IconLink href={link.url} target="_blank" rel="noopener">
                        <img src={WhatsappIcon} tw="w-10 h-10" alt="Whatsapp" />
                      </IconLink>
                    ) : link.name === 'telegram' ? (
                      <IconLink href={link.url} target="_blank" rel="noopener">
                        <img src={TelegramIcon} tw="w-10 h-10" alt="Telegram" />
                      </IconLink>
                    ) : null
                  ))
                )
              }
            </div>

            <span
              onClick={onClose}
              tw="block w-32 mx-auto text-gray-700 bg-gray-200 px-5 py-2 rounded-full text-center transition hover:bg-gray-300 cursor-pointer"
            >
             Close
            </span>
          </div>
        </ModalContentWrapper>
      </ModalContainer>
    </Wrapper>
  );
}
