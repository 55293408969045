import React, { useEffect, useState } from 'react';
import tw, {styled} from 'twin.macro';
import axios from 'axios';
import constants from '../constants';
import defaultQuestionsData from '../data/defaultQuestions';

const SectionTitle = styled.span`
  font-weight: 500;
  font-size: 27px;
  display: block;
`;

const Wrapper = styled.section`
  padding: 5rem 0.5rem;
  @media (min-height: 759px) and (min-width: 700px){
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  background: #f5f5f7;
`;

const ContentWrapper = styled.div`
  ${tw`px-4`}
`;

const FaqItemArrow = styled.span`
  ${tw`p-2 rounded-full ml-3`}
  background: rgb(7, 40, 142, .25);
  color: #07288e;
  transition: 250ms;
`;

const FaqAnswer = styled.div`
  // border-top: 1px solid rgba(0,0,0,0.1);
  // margin-top: 1rem;
  // padding-top: 1rem;
  color: #888;
  transition: 250ms;
  max-height: 0;
  visibility: hidden;
`;

const FaqItem = styled.div`
  padding: 1rem 1rem;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  &.first{border-top: 1px solid rgba(0,0,0,0.1);}
  margin: 0 auto;
  max-width: 700px;
  transition: 200ms;
  overflow: hidden;
  &:hover{
    ${tw` shadow-md `}
    ${FaqItemArrow}{ background: rgb(7, 40, 142, .35); }
  }
  cursor: pointer;

  ${(props) => props.isOpen && `
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    ${FaqItemArrow}{
      transform: rotate(90deg);
      background: rgb(7, 40, 142, .35); 
    }
    ${FaqAnswer} {
      border-top: 1px solid rgba(0,0,0,0.1);
      margin-top: 1rem;
      padding-top: 1rem;
      max-height: 200px;
      visibility: visible;
      overflow: auto;
    }
  `}
`;

const NextBtn = styled.spn`
  background: #00c6f6;
  ${tw`px-5 py-2 rounded hover:bg-blue-500 transition text-white flex items-center cursor-pointer`}
  ${(props) => props.isDisabled && `
    opacity: 0.5;
    &:hover{ background: #00c6f6; }
  `}
`;

const PrevBtn = styled.spn`
  ${tw`px-5 py-2 rounded bg-gray-200 hover:bg-gray-300 transition text-gray-800 flex items-center mr-5 cursor-pointer`}
  ${(props) => props.isDisabled && tw` opacity-50 hover:bg-gray-200 `}
`;

export default function Faq() {
  const [openedFaq, setOpenedFaq] = useState(0);
  const [questions, setQuestions] = useState({
    loading: true,
    data: []
  });
  const [offset, setOffset] = useState(0);
  const LIMIT = 4; 
  const [noMore, setNoMore] = useState({
    prev: true,
    next: false,
  });

  const handleToggle = id => e => {
    // if user click on the opened item, close it.
    if(openedFaq === id){
      setOpenedFaq(0);
    }else{
      setOpenedFaq(id);
    }
  }
  // /faq?_start=10&_limit=10
  useEffect(() => {
    (async function(){
      try{
        const resp = await axios.get(`${constants.API_URL}/faqs`, {
          params: {
            _start: offset,
            _limit: LIMIT
          }
        });

        setNoMore({
          prev: offset === 0,
          next: resp.data.length < LIMIT
        });

        setQuestions({
          loading: false,
          data: resp.data
        })
      }catch(err){
        // if error happened in the pai, just grab the back up.
        // so we don't display just a blank page
        let data = defaultQuestionsData.slice(offset, LIMIT+offset);
        setNoMore({
          prev: offset === 0,
          next: data.length < LIMIT
        });

        setQuestions({
          loading: false,
          data: data
        })
      }
    }());
  }, [offset]);

  const handlePagintion = (action) => e => {
    if(action === 'prev' && !noMore.prev){
      setOffset(offset - LIMIT)
    }else if(action === 'next' && !noMore.next){
      setOffset(offset + LIMIT);
    }
  }
 
  return (
    <Wrapper id="faq">
      <ContentWrapper>
        <div tw="mb-10 text-center">
          <SectionTitle> These are the most aksed questions, answered for you. </SectionTitle>
          <p tw="text-gray-400 text-sm"> Read about the Frequently asked questions about and in islam. </p>
        </div>
        {
          questions.loading ? (
            <div tw="my-20 text-center">
              <p tw="text-gray-600"> We are getting the questions... </p>
              <p tw="text-center w-16 h-16 mx-auto">
                <svg tw="w-16 h-16 mx-auto text-center" version="1.1" id="L4" x="0px" y="0px" viewBox="0 0 50 100" enable-background="new 0 0 0 0">
                  <circle fill="#333" stroke="none" cx="6" cy="50" r="6">
                    <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1"></animate>    
                  </circle>
                  <circle fill="#333" stroke="none" cx="26" cy="50" r="6">
                    <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.2"></animate>       
                  </circle>
                  <circle fill="#333" stroke="none" cx="46" cy="50" r="6">
                    <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.3"></animate>     
                  </circle>
                </svg>
              </p>
            </div>
          ) : (
            <>
              {
                questions.data.map((question, i) => (
                  <FaqItem key={i} className={i === 0 ? 'first' : ''} onClick={handleToggle(question.id)} isOpen={openedFaq === question.id}>
                    <div tw="flex items-center justify-between">
                      <span>{question.question}</span>
                      <FaqItemArrow>
                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><polyline points="9 18 15 12 9 6"></polyline></svg>
                      </FaqItemArrow>
                    </div>
                    <FaqAnswer>{question.answer}</FaqAnswer>
                  </FaqItem>
                ))
              }
              {
                offset === 0 && questions.data.length < LIMIT ? (
                  null
                ) : (
                  <div tw="mt-8 flex items-center justify-center">
                    <PrevBtn
                      isDisabled={noMore.prev}
                      onClick={handlePagintion('prev')}
                    >
                      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.2426 6.34317L14.8284 4.92896L7.75739 12L14.8285 19.0711L16.2427 17.6569L10.5858 12L16.2426 6.34317Z" fill="currentColor" /></svg>
                      Previous
                    </PrevBtn>
                    <NextBtn
                      isDisabled={noMore.next}
                      onClick={handlePagintion('next')}
                    >
                      Next
                      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.5858 6.34317L12 4.92896L19.0711 12L12 19.0711L10.5858 17.6569L16.2427 12L10.5858 6.34317Z" fill="currentColor" /></svg>
                    </NextBtn>
                  </div>
                )
              }
            </>
          )
        }
      </ContentWrapper>
    </Wrapper>
  )
}
